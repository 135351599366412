/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
import { Box, Button, FormControl, FormControlLabel, FormGroup, Grid, Modal, Paper, Radio, RadioGroup } from '@material-ui/core';
import { ArrowBackIos, NewReleases } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Skeleton } from '@mui/material';
import { PAYMENT_METHOD } from 'constants/Enums';
import { QR_CODE_BANK_PAYMENT_METHOD } from 'constants/Images/payment';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import ImageFallback, { ImageFallbackStatic } from 'utils/ImageFallback';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const PaymentMethodsTab = ({
  openTab = true,
  setOpenTab = () => { },
  translateCheckout = () => { },
  paymentMethods = [],
  selectedVal = '',
  handleChange,
}) => {
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(selectedVal);
  const handleChangePaymentMethodOption = (e) => {
    setPaymentMethodSelected(e.target.value);
  };
  const handleClose = () => {
    setOpenTab(false);
  };

  const handleSubmitPaymentMethod = () => {
    const e = { target: { value: paymentMethodSelected } };
    handleChange(e);
    setOpenTab(false);
  };

  return (
    <Modal
      open={openTab}
      handleClose={handleClose}
      fullScreen
      fullWidth
      style={{
        backgroundColor: '#fff',
        position: 'fixed',
        zIndex: '1300',
        inset: '0px',
      }}
    >
      <Grid container direction="column" className={styles.containerModal}>
        <Grid item onClick={handleClose} className={styles.headerBack}>
          <Button className={styles.iconBackButton}>
            <ArrowBackIos style={{ width: '16px', height: '16px' }} />
            <span className={styles.titleBackButton}>{translateCheckout('payment_method')}</span>
          </Button>
        </Grid>
        <Grid item className={styles.listItemsBox}>
          <Grid container direction="column" style={{ height: '100%', flexWrap: 'unset' }}>
            <div className={styles.listItems}>
              <FormControl component="fieldset" style={{ width: '100%', height: '85%', marginTop: 8 }}>
                <FormGroup className={styles.radioGroup}>
                  {paymentMethods?.length > 0 ? (
                    paymentMethods.map((item) => (
                      <RadioGroup value={paymentMethodSelected} className={styles.groupCheckBox} onChange={handleChangePaymentMethodOption}>
                        <FormControlLabel
                          value={item.code}
                          disabled={item.isDisable}
                          control={<Radio size="small" />}
                          label={
                            <Box display="flex" gridGap={8} alignItems="center">
                              <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} gridGap={4}>
                                <h6 className={styles.titleContent}>{item.name}</h6>
                                {item.additionFeeText.length > 0 && !item.isDisable && (
                                  <span className={styles.subTitleContent}>
                                    {translateCheckout('addition_fee_text', { text: item.additionFeeText })}
                                  </span>
                                )}
                              </Box>

                              <Box display="flex" flexWrap="no-wrap" gridGap={4}>
                                {item?.svgIcons?.length > 0 &&
                                  item.svgIcons.map((svgIconURL) => (
                                    <ImageFallback key={svgIconURL} src={svgIconURL} alt={item.name} width="32px" height="32px" />
                                  ))}
                              </Box>
                            </Box>
                          }
                        />
                      </RadioGroup>
                    ))
                  ) : (
                    <>
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                      <Skeleton variant="rect" width="100%" height={70} />
                    </>
                  )}
                </FormGroup>
              </FormControl>
            </div>
            <div className={styles.listItems}>
              <div className={styles.donePaymentButtonBox}>
                <Button variant="contained" color="primary" className={styles.donePaymentButton} onClick={handleSubmitPaymentMethod}>
                  {translateCheckout('confirm')}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const methodSubTitle = ({ subTitle, isDisable, code, additionFeeText, translateCheckout }) => {
  let content = '';
  if ([PAYMENT_METHOD.TRANSFER, PAYMENT_METHOD.COD, PAYMENT_METHOD.CREDIT].includes(code)) {
    if (subTitle.length > 0 && !isDisable) {
      content = subTitle.replace('{percentage}%', additionFeeText);
    }
  } else if (additionFeeText.length > 0 && !isDisable) {
    content = translateCheckout('addition_fee_text', { text: additionFeeText });
  }


  return content.length > 0
    ? <div className={styles.subTitle}>{content}</div>
    : <></>
};

const PaymentMethodItem = React.memo(({ item, isSelected, onChange, translateCheckout }) => {
  const { code, name, additionFeeText, url, isDisable, svgIcons = [], subTitle } = item || {};

  return (
    <RadioGroup className={styles.fieldset} value={isSelected && code} onChange={onChange}>
      <FormControlLabel
        value={code}
        disabled={isDisable}
        control={
          <Radio
            classes={{
              root: isSelected ? styles.checkbox_color : styles.noncheckbox_color,
            }}
            data-test="radio-checkout-payment"
          />
        }
        label={
          <Box display="flex" gridGap={8} alignItems="center">
            <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} gridGap={4}>
              <h6 className={styles.fw500}>{name}</h6>
              {methodSubTitle({ subTitle, isDisable, code, additionFeeText, translateCheckout })}
              {code === PAYMENT_METHOD.TRANSFER && url && (
                <a href={url} target="_blank" rel="noreferrer">
                  &nbsp;({translateCheckout('guide_transfer')})
                </a>
              )}
            </Box>

            <Box display="flex" flexWrap="no-wrap" gridGap={4}>
              {svgIcons?.length > 0 &&
                svgIcons.map((svgIconURL) => <ImageFallback key={svgIconURL} src={svgIconURL} alt={name} width="24px" height="24px" />)}
            </Box>
          </Box>
        }
      />
    </RadioGroup>
  );
});

const PaymentMethod = ({
  handleChange,
  selectedValue = '',
  paymentMethods = [],
  paymentMethodDefault,
  user,
  translateCheckout,
  isMobile = false,
}) => {
  const { wardCode = '', districtCode = '', provinceCode = '' } = user;
  const selectedVal = useMemo(() => selectedValue || paymentMethodDefault?.code || '', [selectedValue, paymentMethodDefault?.code]);
  const selectedPaymentMethod = paymentMethods.find((method) => method.code === selectedVal);
  const { code, description, errorMessage, subTitle } = selectedPaymentMethod || {};
  const checkExistDescriptionContent = useMemo(() => description && description.length > 0 && description !== '<p></p>\n', [description]);
  // const [openTab, setOpenTab] = useState(true);

  return (
    <Paper className={styles.root} elevation={4}>
      {isMobile ? (
        <>
          <Grid container alignItems="space-between">
            <Grid item xs={12}>
              <h1 className={styles.title}>{translateCheckout('payment_method')}</h1>
            </Grid>
            {/* <Grid item xs={1} style={{ position: 'relative' }}>
              <IconButton style={{ padding: 0, position: 'absolute', top: 0, right: 0 }}>
                <ArrowForwardIos style={{ width: '14px', height: '14px', color: '#00000061' }} />
              </IconButton>
            </Grid> */}
            <Grid container direction="column" style={{ height: '100%', flexWrap: 'unset' }}>
              <div className={styles.listItems}>
                <FormControl component="fieldset" style={{ width: '100%', height: '85%', marginTop: 8 }}>
                  <FormGroup className={styles.radioGroup}>
                    {paymentMethods?.length > 0 ? (
                      paymentMethods.map((item) => (
                        <RadioGroup value={selectedVal} className={styles.groupCheckBox} onChange={(e) => handleChange(e)}>
                          <FormControlLabel
                            value={item.code}
                            disabled={item.isDisable}
                            control={<Radio />}
                            label={
                              <Box display="flex" gridGap={8} alignItems="center">
                                <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} gridGap={4}>
                                  <h6 className={styles.titleContent}>{item.name}</h6>
                                  {methodSubTitle({
                                    subTitle: item?.subTitle || '',
                                    isDisable: item?.isDisable,
                                    code: item?.code || '',
                                    additionFeeText: item?.additionFeeText || '',
                                    translateCheckout
                                  })}
                                  {/* {item.additionFeeText.length > 0 && !item.isDisable && <span className={styles.subTitleContent}>{translateCheckout('addition_fee_text', { text: item.additionFeeText })}</span>} */}
                                </Box>

                                <Box display="flex" flexWrap="no-wrap" gridGap={4}>
                                  {item?.svgIcons?.length > 0 &&
                                    item.svgIcons.map((svgIconURL) => (
                                      <ImageFallback key={svgIconURL} src={svgIconURL} alt={item.name} width="32px" height="32px" />
                                    ))}
                                </Box>
                              </Box>
                            }
                          />
                        </RadioGroup>
                      ))
                    ) : (
                      <>
                        <Skeleton variant="rect" width="100%" height={70} />
                        <Skeleton variant="rect" width="100%" height={70} />
                        <Skeleton variant="rect" width="100%" height={70} />
                        <Skeleton variant="rect" width="100%" height={70} />
                        <Skeleton variant="rect" width="100%" height={70} />
                        <Skeleton variant="rect" width="100%" height={70} />
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          {/* {selectedPaymentMethod && (
            <Grid container alignItems="space-between">
              <Grid item xs={10}>
                <h6 className={styles.fw500}>{selectedPaymentMethod.name}</h6>
                {selectedPaymentMethod?.additionFeeText?.length > 0
                  && <div className={styles.subTitle}>{translateCheckout('addition_fee_text', { text: selectedPaymentMethod.additionFeeText })}</div>}
              </Grid>
              <Grid item xs={2} style={{ position: 'relative' }}>
                {selectedPaymentMethod?.svgIcons?.length > 0 && selectedPaymentMethod.svgIcons.map((svgIconURL) => (
                  <ImageFallback key={svgIconURL} src={svgIconURL} alt={selectedPaymentMethod.name} width="40px" height="40px" />
                ))}
              </Grid>
            </Grid>
          )}
          <PaymentMethodsTab
            openTab={openTab}
            setOpenTab={setOpenTab}
            translateCheckout={translateCheckout}
            paymentMethods={paymentMethods}
            selectedVal={selectedVal}
            handleChange={handleChange}
          /> */}
        </>
      ) : (
        <>
          <h1 className={styles.title}>{translateCheckout('payment_method')}</h1>
          <FormControl component="fieldset">
            <div className={styles.paymentMethodsGrid}>
              {paymentMethods?.length > 0 ? (
                paymentMethods.map((item) => (
                  <PaymentMethodItem
                    key={item.code}
                    item={item}
                    isSelected={selectedVal === item.code}
                    onChange={handleChange}
                    translateCheckout={translateCheckout}
                  />
                ))
              ) : (
                <>
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                  <Skeleton variant="rect" width="100%" height={70} />
                </>
              )}
            </div>

            {errorMessage && <div className={styles.subTitle}>{errorMessage}</div>}

            {/* {!!subTitle && (
              <div className={styles.boxPercentage}>
                <NewReleases width={24} height={24} color="#0e0eef" />
                <h5 className={styles.percentageText}>{selectedPaymentMethod?.subTitle}</h5>
              </div>
            )} */}
            {!!checkExistDescriptionContent && (
              <React.Fragment key={uuidv4()}>
                <Alert className={styles.bank_info} icon={false} severity="info">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid
                      item
                      spacing={2}
                      className={styles.content}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                    {code === PAYMENT_METHOD.TRANSFER && (
                      <Grid item justifyContent="flex-end" className={styles.content} style={{ width: '135', display: 'inline' }}>
                        <ImageFallbackStatic src={QR_CODE_BANK_PAYMENT_METHOD} width="135" height="135" />
                        <p className={styles.bank_info_content}>{translateCheckout('scan_to_pay')}</p>
                      </Grid>
                    )}
                  </Grid>
                </Alert>
              </React.Fragment>
            )}
          </FormControl>
        </>
      )}
      {
        (!wardCode || !districtCode || !provinceCode) && (
          <Alert severity="info" style={{ borderRadius: '8px', marginTop: '15px' }}>
            {translateCheckout('please_enter_full')} {!wardCode && translateCheckout('ward')}
            {!districtCode && `, ${translateCheckout('district')}`}
            {!provinceCode && `, ${translateCheckout('province')}`} {translateCheckout('in')}{' '}
            <b>
              <Link href="/my-account">
                <a href="/my-account" target="_blank">
                  {translateCheckout('account_info')}
                </a>
              </Link>
            </b>{' '}
            {translateCheckout('to_get_discount')}
          </Alert>
        )
      }
    </Paper>
  );
};

export default PaymentMethod;
