import ProductTag from 'components-v2/organisms/pages/landingPageThai/mocules/ProductTag';
import TagTypeProps from 'constants/TagTypeProps';
import { useAuth } from 'context';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { REGION_MB, TAG_HANG_DAT_TRUOC, TAG_NEW } from 'sysconfig';
import { DateTimeUtils } from 'utils';
import { useStore } from 'zustand-lib/storeGlobal';

export const tagTypePropsArr = [
  'OUT_OF_STOCK',
  'SUSPENDED',
  'GIFT',
  'FLASH_SALE',
  'MEGA_DAY',
  'DEAL',
  TAG_HANG_DAT_TRUOC,
  'FV2U',
  '2ABC',
  'HOADONNHANH',
  'BAN_CHAY',
  'BANCHAY',
  'Y7GB',
  '84AB',
  '3ABC',
];
const tagIconArr = ['GIAONHANH', 'W80T', 'BAN_CHAY', 'BANCHAY', 'HOADONNHANH'];
const tagNoDisplayArr = [TAG_NEW, 'NEAR_EXPIRATION'];

export const getTagName = (item, getStyleBySlugOfTag) => {
  if (item === 'NEAR_EXPIRATION') {
    return 'Cận date';
  }
  if (tagNoDisplayArr?.indexOf(item) >= 0) {
    return null;
  }
  const tagInfo = TagTypeProps[item];
  if (tagInfo?.name) {
    return tagInfo.name;
  }
  return getStyleBySlugOfTag(item)?.name || '';
};

// notes
// cận date chưa làm ,  nếu có status cận date
// khuyến mãi , nếu có deal tự thêm mã giảm giá vào tag
// nên chuyển về 1 cái , làm sau
const TagType = ({ item, exp, isTooltip = false, isDisplayFull = false, link, isLinkTagDeal, index }) => {
  const { t: translateCommon } = useTranslation('common');
  const getStyleBySlugOfTag = useStore((state) => state.getStyleBySlugOfTag);
  const { customerInfo } = useAuth();

  // replace PROMOTION to DEAL
  // because we don't have DEAL tag in back-end
  // and we don't have PROMOTIONS tag in front-end also
  // Note: DEAL === PROMOTIONS (confirmed by Mr. Thuan Nguyen)
  const tagName = useMemo(() => {
    if (item === 'PROMOTIONS') return 'DEAL'
    return item
  }, [])

  let props = {};
  if (tagName === TAG_HANG_DAT_TRUOC && REGION_MB.indexOf(`${customerInfo?.provinceCode}`) < 0) {
    return null;
  }
  if (!isDisplayFull && tagNoDisplayArr?.indexOf(tagName) >= 0) {
    return null;
  }

  // nếu ko có option nào thì lấy theo API trả về
  const tagInfo = TagTypeProps[tagName];
  props = tagInfo ? { ...tagInfo, isTooltip } : getStyleBySlugOfTag(tagName);
  if (props && !props?.name) {
    props.name = getStyleBySlugOfTag(tagName)?.name || '';
  }
  if (props && !props?.slug) {
    props.slug = getStyleBySlugOfTag(tagName)?.slug || '';
  }
  if (!props) {
    return null;
  }
  if (tagIconArr?.indexOf(tagName) >= 0) {
    props = { ...props, isTooltip };
  }

  // translate tag số lượng có hạn
  if (tagName === 'LIMIT') {
    props.name = translateCommon('tag.LIMIT');
  }

  Object.assign(props, {code: tagName})
  return <ProductTag link={link} isLinkTagDeal={isLinkTagDeal} index={index} {...props} />;
};

export default TagType;
